import { FC, ReactNode } from 'react';
import Heading from '@/components/common/Heading';
import {
  TextWithDropShadow,
  TextWithDropShadowColor,
} from '../TextWithDropShadow/textWithDropShadow';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { StrapiComponentInfoBanner } from '../section/interfaces';

interface Props {
  props: Partial<StrapiComponentInfoBanner>;
  className?: string;
  children?: ReactNode;
}

export const ThemedHero: FC<Props> = ({
  props: {
    title,
    title2,
    subtext,
    rounded = false,
    background,
    padding,
    minHeight,
    textPosition,
  },
  className,
}) => {
  const titleColor: TextWithDropShadowColor = {
    text: title?.color?.color || 'text-white',
    shadow: 'text-white',
  };
  const subtextColor: TextWithDropShadowColor = {
    text: subtext?.color?.color || 'text-white',
    shadow: 'text-white',
  };

  const titleSize = (title && title.size?.size.match(/\d+/)?.[0]) || '3';
  const subtextSize = (subtext && subtext.size?.size.match(/\d+/)?.[0]) || '5';

  const contentAlignmanet =
    textPosition === 'middle'
      ? 'items-center  justify-center'
      : textPosition === 'top'
        ? 'items-start justify-start'
        : 'items-end justify-end';

  const paddingClass =
    padding &&
    `p-0 pt-${padding.top} pr-${padding.right} pb-${padding.bottom} pl-${padding.left} `;

  const heightClass = minHeight ? `min-${minHeight.value}` : '';

  return (
    <div
      className={twMerge(
        'mrc-info-card-banner flex',
        contentAlignmanet,
        className,
        clsx(paddingClass),
        clsx(heightClass),
        rounded && 'rounded-3xl'
      )}
    >
      <div className={`flex w-full flex-col gap-y-6 p-7 text-center`}>
        {title && !title.hidden && title.text && (
          <Heading size={`${titleSize}`}>
            <TextWithDropShadow
              text={title.text}
              color={titleColor}
              displayShadow={title.shadow}
            ></TextWithDropShadow>
          </Heading>
        )}
        {title2 && !title2.hidden && title2.text && (
          <Heading size={`${titleSize}`}>
            <TextWithDropShadow
              text={title2.text}
              color={titleColor}
              displayShadow={title2.shadow}
            ></TextWithDropShadow>
          </Heading>
        )}
        {subtext && !subtext.hidden && subtext.text && (
          <Heading size={`${subtextSize}`}>
            <TextWithDropShadow
              text={subtext.text}
              color={subtextColor}
              displayShadow={subtext.shadow}
            ></TextWithDropShadow>
          </Heading>
        )}
      </div>
    </div>
  );
};
