import { BackgroundValue, getBackground } from '@/theme/backgrounds';
import { twMerge } from 'tailwind-merge';

export const ThemedSection = <
  T extends {
    padding?: any;
    background?: {
      style?: string;
      url?: string;
      imagePosition?: { position?: string };
    };
  },
>({
  children,
  content,
}: {
  children: React.ReactNode;
  content: T;
}) => {
  const data = content;
  const background = data?.background || {};
  const backgroundStyle =
    (background?.style && (background.style as BackgroundValue)) || undefined;

  const style =
    background?.url && background.style
      ? {
          backgroundImage: `url(${background.url}), ${(backgroundStyle && getBackground(backgroundStyle)) || ''}`,
          backgroundSize: 'cover',
          backgroundPosition: background?.imagePosition?.position || 'center',
          backgroundRepeat: 'no-repeat',
        }
      : {
          background: (backgroundStyle && getBackground(backgroundStyle)) || '',
        };

  return (
    <section
      style={style}
      className={twMerge(
        'section-fade-2 flex-co relative flex',
        data.padding ? 'gap-y-12 py-14 md:py-20 lg:gap-y-16 xl:py-24' : ''
      )}
    >
      <div className='mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'>
        {children}
      </div>
    </section>
  );
};
