import ReviewCard, { StrapiFlatReviewData } from './card';

export default function FlatReviews({
  reviewData,
  title,
}: {
  title: string;
  reviewData: StrapiFlatReviewData[];
}) {
  return (
    <>
      <div className='mx-auto mb-9 w-full text-center md:w-8/12'>
        <h2 className='false mb-10 text-center text-4xl font-bold md:text-6xl lg:text-6xl'>
          {title}
        </h2>
      </div>
      <div className='flex w-full flex-wrap justify-center gap-6 lg:gap-8'>
        {reviewData &&
          reviewData.map((review, idx) => (
            <ReviewCard reviewBlock={review} key={idx} />
          ))}
      </div>
    </>
  );
}
