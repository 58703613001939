'use client';

import { FC } from 'react';
import Slider, { type Settings } from 'react-slick';
import { twMerge } from 'tailwind-merge';
import { MdArrowForwardIos, MdArrowBackIos } from 'react-icons/md';
import Heading from 'components/common/Heading';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useDeviceDetect } from '@/utils/useDeviceDetect';
import { useStore } from 'zustand';
import { useMapStore } from '@/stores/map/mapStore';
import WhereToBuyBtn from '@/components/button/where-to-buy-btn';
import { usePageTheme } from '@/stores/page-theme';
import { BackgroundType, Theme } from '@/types/enhancedSlider';
import { backgrounds } from '@/theme/backgrounds';

interface EnhancedSliderProps {
  title?: string;
  subtextTop?: string;
  subtextBottom?: string;
  products: any[];
  headingSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | '40px';
  headingColor?: string;
  shadowText?: boolean;
  background?: BackgroundType;
  backgroundColor?: string;
  backgroundImage?: string;
  className?: string;
  pageTheme?: Theme;
  dividerColor?: string;
}

interface SliderArrowProps {
  onClick?: () => void;
  direction: 'prev' | 'next';
  pageTheme?: string;
}

const SliderArrow: FC<SliderArrowProps> = ({
  onClick,
  direction,
  pageTheme = 'default',
}) => {
  return (
    <div
      className={twMerge(
        'enhanced-slider__arrow',
        `absolute top-[50%] z-[2] flex size-9 -translate-y-[50%] cursor-pointer flex-row items-center justify-center rounded-full text-sm transition-all md:size-14 md:text-lg`,
        pageTheme === 'purple' || pageTheme === 'black'
          ? 'bg-white text-primary hover:bg-white/90'
          : 'bg-white text-primary hover:bg-white/90',
        direction === 'prev' ? '-left-5' : '-right-5'
      )}
      onClick={onClick}
    >
      {direction === 'prev' ? <MdArrowBackIos /> : <MdArrowForwardIos />}
    </div>
  );
};

const EnhancedSlider: FC<EnhancedSliderProps> = ({
  title,
  subtextTop,
  subtextBottom,
  products,
  headingSize = 'h3',
  headingColor,
  shadowText = false,
  background = 'inherit',
  backgroundColor,
  pageTheme,
  dividerColor,
}) => {
  const isMobile = useDeviceDetect();
  const { setModalMapOpen } = useStore(useMapStore, (state) => ({
    setModalMapOpen: state.setModalMapOpen,
  }));
  const { bgColor } = useStore(usePageTheme, (state) => ({
    bgColor: state.bgColor,
  }));

  const settings: Settings = {
    arrows: products && products.length > 1,
    className: 'enhanced-product-slider',
    dots: false,
    infinite: products && products.length > 1,
    speed: 500,
    slidesToShow: products ? Math.min(2, products.length) : 1,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: false,
    centerPadding: '0px',
    adaptiveHeight: false,
    variableWidth: false,
    prevArrow: <SliderArrow direction='prev' pageTheme={pageTheme} />,
    nextArrow: <SliderArrow direction='next' pageTheme={pageTheme} />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: products ? Math.min(2, products.length) : 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        },
      },
    ],
  };

  const getBackgroundStyle = () => {
    if (background === 'solid' && backgroundColor) {
      return { backgroundColor };
    }

    if (background === 'inherit') {
      return {};
    }

    if (background && typeof background === 'string') {
      const standardBackgrounds = [
        'solid',
        'inherit',
        'gradient',
        'gradient-mesh',
        'mesh',
        'image',
      ];
      if (!standardBackgrounds.includes(background)) {
        if (backgrounds[background as keyof typeof backgrounds]) {
          return {
            background: backgrounds[background as keyof typeof backgrounds],
          };
        }
        return { background: background };
      }
    }

    return {};
  };

  const getTextColor = () => {
    if (headingColor) {
      if (headingColor === 'white') return 'text-white';
      if (headingColor === 'black') return 'text-black';
      if (headingColor === 'blue') return 'text-primary-dark';
      return headingColor;
    }

    if (pageTheme === 'purple' || pageTheme === 'black') return 'text-white';
    return 'text-primary-dark';
  };

  const renderProductImage = (product: any) => {
    const imageUrl = product.thumbnail?.url || null;

    if (imageUrl) {
      return (
        <img
          src={imageUrl}
          alt='Product Image'
          className='h-full w-full object-contain'
          onError={(e) => {
            e.currentTarget.style.display = 'none';
            e.currentTarget.parentElement?.classList.add(
              'flex',
              'items-center',
              'justify-center'
            );

            const svgPlaceholder = document.createElementNS(
              'http://www.w3.org/2000/svg',
              'svg'
            );
            svgPlaceholder.setAttribute('width', '100%');
            svgPlaceholder.setAttribute('height', '100%');
            svgPlaceholder.setAttribute('viewBox', '0 0 400 400');
            svgPlaceholder.setAttribute('class', 'h-full w-full');

            const rect = document.createElementNS(
              'http://www.w3.org/2000/svg',
              'rect'
            );
            rect.setAttribute('width', '100%');
            rect.setAttribute('height', '100%');
            rect.setAttribute('fill', '#f3f4f6');

            svgPlaceholder.appendChild(rect);
            e.currentTarget.parentElement?.appendChild(svgPlaceholder);
          }}
        />
      );
    }

    return null;
  };

  return (
    <div
      className={`relative w-full ${background === 'inherit' && !backgroundColor ? bgColor : ''}`}
      style={getBackgroundStyle()}
    >
      <div className='enhanced-slider__container container mx-auto flex flex-col items-center justify-start gap-8 px-5 md:gap-6'>
        {(title || subtextTop || subtextBottom) && (
          <div
            className={`enhanced-slider__header mx-auto w-full pt-28 text-center md:w-8/12 ${headingSize === '40px' ? 'min-h-[400px] pb-16' : ''}`}
          >
            {title && (
              <Heading
                size={
                  headingSize === 'h1'
                    ? 1
                    : headingSize === 'h2'
                      ? 2
                      : headingSize === 'h3'
                        ? 3
                        : headingSize === 'h4'
                          ? 4
                          : headingSize === 'h5'
                            ? 5
                            : '40px'
                }
                className={twMerge(
                  'enhanced-slider__title',
                  shadowText && 'text-shadow-lg',
                  getTextColor(),
                  headingSize === '40px'
                    ? 'text-[40px] md:text-[60px] lg:text-[80px]'
                    : ''
                )}
              >
                {title}
              </Heading>
            )}
            {(subtextTop || subtextBottom) && (
              <div
                className={`enhanced-slider__subtext mt-16 flex flex-col items-center gap-6 md:mt-12 md:flex-row md:items-center md:justify-center md:gap-0 ${headingSize === '40px' ? 'mt-12 md:mt-[34px]' : ''}`}
              >
                <div className='enhanced-slider__subtext-top flex-1 text-right md:pr-8'>
                  {subtextTop && (
                    <div
                      className={twMerge(
                        'text-center opacity-80',
                        getTextColor()
                      )}
                      style={{ fontSize: '18px' }}
                    >
                      {subtextTop}
                    </div>
                  )}
                </div>
                <div
                  className='enhanced-slider__divider mx-5 h-px w-[100%] shrink-0 opacity-50 md:mx-0 md:h-[92px] md:w-px'
                  style={{
                    backgroundColor:
                      dividerColor ||
                      (pageTheme?.toLowerCase() === 'black' ||
                      pageTheme?.toLowerCase() === 'purple'
                        ? 'white'
                        : 'black'),
                  }}
                ></div>
                <div className='enhanced-slider__subtext-bottom flex-1 text-left md:pl-8'>
                  {subtextBottom && (
                    <div
                      className={twMerge(
                        'text-center opacity-80',
                        getTextColor()
                      )}
                      style={{ fontSize: '18px' }}
                    >
                      {subtextBottom}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        <div
          className='enhanced-slider__wrapper relative -mt-4 w-full
         overflow-hidden px-12 md:mt-0'
        >
          <div className='enhanced-slider__track [&_.slick-track]:!flex [&_.slick-track]:!justify-center'>
            <div className='w-full pb-20'>
              {products && products.length > 0 ? (
                <Slider {...settings}>
                  {products.map((product) => (
                    <div
                      key={product.id}
                      className='enhanced-slider__slide px-5'
                    >
                      <div className='mx-auto h-[580px] w-[376px] rounded-[20px] border border-gray-200 bg-white p-9 shadow-sm'>
                        <div className='enhanced-slider__image aspect-square w-full overflow-hidden'>
                          {renderProductImage(product)}
                        </div>
                        <div className='enhanced-slider__content mt-6 flex w-full flex-col items-stretch justify-between gap-4'>
                          <Heading
                            size={5}
                            className='enhanced-slider__product-title text-center text-black'
                          >
                            {product.model}
                          </Heading>
                          <div className='enhanced-slider__product-info flex flex-col items-center justify-center gap-2'>
                            <p className='enhanced-slider__product-price text-[20px] font-bold text-[#0071E3]'>
                              ${product.price}
                            </p>
                          </div>
                          <div
                            className={twMerge(
                              'enhanced-slider__cta',
                              'flex justify-center'
                            )}
                          >
                            <WhereToBuyBtn
                              isMobile={isMobile}
                              setMapOpen={setModalMapOpen}
                              title='Where to Buy'
                              color={
                                pageTheme === 'purple' || pageTheme === 'black'
                                  ? 'bg-white text-primary hover:bg-white/90'
                                  : 'bg-primary text-white hover:bg-primary-dark'
                              }
                            />
                          </div>
                          {product.coverageArea && (
                            <div className='enhanced-slider__product-coverage mt-4 text-center text-[18px] text-black'>
                              Coverage Area:{' '}
                              {product.coverageArea.toLocaleString()} sqft.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className='flex h-[580px] w-full items-center justify-center'>
                  <p className='text-gray-500'>No products available</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { EnhancedSlider };
