'use client';

import React, { useEffect, useState } from 'react';
import { EnhancedSlider } from './EnhancedSlider';
import { StrapiEnhancedSlider, BackgroundType } from '@/types/enhancedSlider';
import { usePageTheme } from '@/stores/page-theme';

interface EnhancedSliderWrapperProps {
  strapiData: StrapiEnhancedSlider;
  bgColor?: string;
}

export const EnhancedSliderWrapper: React.FC<EnhancedSliderWrapperProps> = ({
  strapiData,
  bgColor,
}) => {
  const [data, setData] = useState<any>(null);
  const { pageTheme } = usePageTheme();

  useEffect(() => {
    if (!strapiData) return;

    const transformedData = {
      title: strapiData.title,
      subtextTop: strapiData.subtextTop,
      subtextBottom: strapiData.subtextBottom,
      headingSize: (strapiData.headingSize?.size?.toLowerCase() || 'h3') as
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | '40px',
      headingColor: strapiData.headingColor?.color || undefined,
      shadowText: strapiData.shadowText || false,
      background: strapiData.backgroundColor
        ? 'solid'
        : ((strapiData.background?.style === 'mesh'
            ? 'gradient-mesh'
            : strapiData.background?.style || 'inherit') as BackgroundType),
      backgroundColor: strapiData.backgroundColor || undefined,
      backgroundImage: strapiData.backgroundImage?.data?.[0]?.attributes?.url,
      dividerColor: strapiData.dividerColor || undefined,
      products: (strapiData.products?.data || []).map((product) => {
        return {
          id: product.id,
          model: product.attributes?.modelNumber,
          price: product.attributes?.msrp,
          coverageArea: product.attributes?.coverageArea,
          thumbnail: {
            url: product.attributes?.thumbnail?.data?.attributes?.url || null,
          },
        };
      }),
    };

    setData(transformedData);
  }, [strapiData, setData, pageTheme, bgColor]);

  if (!data) {
    return null;
  }

  return <EnhancedSlider {...data} pageTheme={pageTheme} bgColor={bgColor} />;
};
