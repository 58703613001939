'use client';

import { getBackground } from '@/theme/backgrounds';
import React, { useEffect } from 'react';
import {
  StrapiComponentBackground,
  StrapiTextSubText,
} from '../section/interfaces';
import Heading from '../common/Heading';
import {
  TextWithDropShadow,
  TextWithDropShadowColor,
} from '../TextWithDropShadow/textWithDropShadow';
// import '@google/model-viewer';

interface HLVS3DComponentProps {
  imageURL: string;
  isTitle: boolean;
  isSubtext: boolean;
  title: StrapiTextSubText;
  subtext?: StrapiTextSubText;
  background?: StrapiComponentBackground;
}

const HLVS3DModelViewer: React.FC<HLVS3DComponentProps> = ({
  imageURL,
  isTitle,
  isSubtext,
  title,
  subtext,
  background,
}) => {
  const titleSize = (title && title.size?.size.match(/\d+/)?.[0]) || '3';
  const subtextSize = (subtext && subtext.size?.size.match(/\d+/)?.[0]) || '5';
  const titleColor: TextWithDropShadowColor = {
    text: title?.color?.color || 'text-white',
    shadow: 'text-white',
  };
  const subtextColor: TextWithDropShadowColor = {
    text: subtext?.color?.color || 'text-white',
    shadow: 'text-white',
  };

  console.log(!title.hidden, titleSize, titleColor, title);

  //   const showSubtext = isSubtext && subtext?.text && !subtext.hidden;
  const backgroundStyle = background?.url
    ? {
        backgroundImage: `url(${background.url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }
    : { background: background?.style && getBackground(background?.style) };

  useEffect(() => {
    import('@google/model-viewer');
  }, []);

  return (
    <div
      className='flex w-full flex-col items-center justify-center p-4'
      style={backgroundStyle}
    >
      <div className={`flex w-full flex-col gap-y-6 p-7 text-center`}>
        {title && !title.hidden && title.text && (
          <Heading size={`${titleSize}`}>
            <TextWithDropShadow
              text={title.text}
              color={titleColor}
              displayShadow={title.shadow}
            ></TextWithDropShadow>
          </Heading>
        )}
        {subtext && !subtext.hidden && subtext.text && (
          <Heading size={`${subtextSize}`}>
            <TextWithDropShadow
              text={subtext.text}
              color={subtextColor}
              displayShadow={subtext.shadow}
            ></TextWithDropShadow>
          </Heading>
        )}
      </div>

      <model-viewer
        src={imageURL}
        alt={title?.text || '3D Model'}
        auto-rotate
        camera-controls
        ar
        style={{ width: '100%', height: '400px', maxWidth: '1000px' }}
      />
    </div>
  );
};

export default HLVS3DModelViewer;
