import SideBySide from '@/components/SideBySide';
import { FC } from 'react';
import { StrapiComponentPadding } from '../interfaces';

export interface StrapiSideBySideData {
  headingLeft: {
    text: string;
    shadow: boolean;
    hidden: boolean;
    size: {
      size: string;
    };
    color: {
      color: string;
    };
  };
  bodyLeft: {
    text: string;
    color: {
      color: string;
    };
  };
  headingRight: {
    text: string;
    shadow: boolean;
    hidden: boolean;
    size: {
      size: string;
    };
    color: {
      color: string;
    };
  };
  bodyRight: {
    text: string;
    color: {
      color: string;
    };
  };
  padding: StrapiComponentPadding;
}

export const StrapiSideBySideData: FC<{
  content: { data: StrapiSideBySideData };
}> = (content) => {
  const data = content?.content as unknown as StrapiSideBySideData;
  const paddingClass =
    data?.padding &&
    `p-0 pt-${data.padding.top} pr-${data.padding.right} pb-${data.padding.bottom} pl-${data.padding.left} `;
  return (
    <>
      {data && (
        <section className={paddingClass}>
          <div className='mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'>
            <SideBySide content={data}></SideBySide>
          </div>
        </section>
      )}
    </>
  );
};
