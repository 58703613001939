import { BiSolidCommentDetail } from 'react-icons/bi';

export interface StrapiFlatReviewData {
  id: number;
  content: string;
  hidden: boolean;
}

export default function ReviewCard({
  reviewBlock,
}: {
  reviewBlock: StrapiFlatReviewData;
}) {
  return (
    <div
      className={` flex w-full justify-center gap-6 rounded-2xl bg-[#F4F6F8]  p-9 lg:max-w-[582px]`}
    >
      <div className='flex h-10 min-w-10 items-center justify-center rounded-full bg-[#1C8EFF] p-2 text-base font-medium text-white'>
        <BiSolidCommentDetail />
      </div>
      <div className='text-base font-medium'>{reviewBlock.content}</div>
    </div>
  );
}
