export const PaddedSection = <
  T extends {
    sectionPadding?: any;
  },
>({
  children,
  content,
}: {
  children: React.ReactNode;
  content: T;
}) => {
  const data = content;

  return (
    <section
      className={`section-fade-2 relative flex flex-col ${data.sectionPadding ? 'gap-y-12 py-14 md:py-20 lg:gap-y-16 xl:py-24' : ''}`}
    >
      <div className='mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'>
        {children}
      </div>
    </section>
  );
};
