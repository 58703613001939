import { title } from 'process';
import Heading from '../common/Heading';
import { StrapiSideBySideData } from '../section/sideBySide';
import {
  TextWithDropShadow,
  TextWithDropShadowColor,
} from '../TextWithDropShadow/textWithDropShadow';
import { twMerge } from 'tailwind-merge';

export default function SideBySide({
  content,
}: {
  content: StrapiSideBySideData;
}) {
  const headingLeftColor: TextWithDropShadowColor = {
    text: content.headingLeft?.color?.color || 'text-grey-900',
    shadow: 'text-grey-900',
  };

  const headingRightColor: TextWithDropShadowColor = {
    text: content.headingRight?.color?.color || 'text-grey-900',
    shadow: 'text-grey-900',
  };

  const headingLeftSize =
    (content.headingLeft.text &&
      content.headingLeft.size?.size.match(/\d+/)?.[0]) ||
    '4';

  const headingRightSize =
    (content.headingRight.text &&
      content.headingLeft.size?.size.match(/\d+/)?.[0]) ||
    '4';

  const bodyLeftColor: TextWithDropShadowColor = {
    text: content.bodyLeft?.color?.color || 'text-grey-900',
    shadow: 'text-grey-900',
  };

  const bodyRightColor: TextWithDropShadowColor = {
    text: content.bodyRight?.color?.color || 'text-grey-900',
    shadow: 'text-grey-900',
  };
  return (
    <>
      <div className='container-safe-reliable mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'>
        <div className='flex flex-row flex-wrap justify-center'>
          <div className='flex w-full flex-col gap-y-4 border-b border-r-0 border-gray-300 px-12 py-6 text-center sm:w-1/2 sm:border-b-0 sm:border-r'>
            <Heading size={`${headingLeftSize}`}>
              <TextWithDropShadow
                text={content.headingLeft.text}
                color={headingLeftColor}
                displayShadow={content.headingLeft.shadow}
              ></TextWithDropShadow>
            </Heading>
            <div className={twMerge('text-lg font-normal', bodyLeftColor.text)}>
              {content.bodyLeft.text}
            </div>
          </div>
          <div className='flex w-full flex-1 flex-col gap-y-4 px-12 py-6 text-center sm:w-1/2'>
            <Heading size={`${headingRightSize}`}>
              <TextWithDropShadow
                text={content.headingRight.text}
                color={headingRightColor}
                displayShadow={content.headingRight.shadow}
              ></TextWithDropShadow>
            </Heading>
            <div
              className={twMerge('text-lg font-normal', bodyRightColor.text)}
            >
              {content.bodyRight.text}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
