'use client';

import qs from 'qs';
import React, { useEffect, useMemo } from 'react';
import { NotFoundPage } from 'components/error/404';
import { useLandingPage } from 'stores/landing-page';
import { MainSection } from 'components/section/main';
import ProductPageSkeleton from './skeleton';
import { useStore } from 'zustand';
import { useMapStore } from 'stores/map/mapStore';
import ModalMap from 'components/map/component/modal-map';
import { MRCOOL } from '@/utils/constants';
import { useProducts } from '@/stores/product';
import { usePageTheme } from '@/stores/page-theme';

interface Props {
  params: Promise<{
    slug: string;
  }>;
  searchParams: any;
}

const ProductLandingGeneralPage = ({ params }: Props) => {
  const { setPageTheme } = useStore(usePageTheme, (state) => ({
    setPageTheme: state.setPageTheme,
  }));
  const { landingPage, getLandingPage, isResetLandingPage, isError } =
    useLandingPage();
  const { modalMapOpen, setModalMapOpen } = useStore(useMapStore, (state) => ({
    modalMapOpen: state.modalMapOpen,
    setModalMapOpen: state.setModalMapOpen,
  }));
  const resolvedSlug = React.use(params).slug;
  const { product, getProduct, getRelatedProducts } = useProducts();

  const filterLandingPage = useMemo(() => {
    const search = qs.stringify(
      {
        populate: {
          related_products: {
            populate: ['thumbnail'],
          },
          product_variants: {
            populate: ['images'],
          },
          contents: {
            on: {
              'landing.hero': {
                populate: [
                  'description',
                  'hero_links',
                  'title',
                  'links',
                  'images',
                  'featureImages',
                  'slider',
                  'backgroundColor',
                  'panoramaImages',
                ],
              },
              'landing.enhanced-slider': {
                populate: [
                  'title',
                  'subtext',
                  'products',
                  'products.thumbnail',
                  'headingSize',
                  'headingColor',
                  'shadowText',
                  'theme',
                  'background',
                  'backgroundColor',
                  'backgroundImage',
                ],
              },
              'landing.hero-images': {
                populate: ['links', 'images'],
              },
              'landing.tabs-info': {
                populate: ['data', 'description', 'tag', 'title', 'images'],
              },
              'landing.features-carousel': {
                populate: [
                  'data',
                  'data.images',
                  'description',
                  'title',
                  'with_preview',
                ],
              },
              'landing.feature-accordion': {
                populate: ['data', 'images', 'description', 'title', 'tag'],
              },
              'landing.tips': {
                populate: ['data', 'images', 'description', 'title', 'links'],
              },
              'landing.faqs': {
                populate: ['data'],
              },
              'landing.slider-hero': {
                populate: ['images', 'content', 'backgroundColor', 'videos'],
              },
              'landing.hero-product-showcase': {
                populate: [
                  'description',
                  'data',
                  'data.images',
                  'title',
                  'images',
                  'tag',
                ],
              },
              'landing.rebates': {
                populate: ['content', 'link', 'buttonName'],
              },
              'landing.product-ads': {
                populate: ['description', 'backgroundColor', 'title', 'image'],
              },
              'landing.feature-showcase': {
                populate: ['data', 'data.images'],
              },
              'landing.product-parts': {
                populate: ['data', 'data.image', 'description', 'title'],
              },
              'landing.dealers': {
                populate: ['data', 'icon', 'links'],
              },
              'landing.related-products': {
                populate: ['backgroundColor', 'description', 'tag', 'title'],
              },
              'landing.product-variants': {
                populate: ['backgroundColor', 'description', 'tag', 'title'],
              },
              'landing.info-banner': {
                populate: [
                  'background',
                  'background.style',
                  'background.image',
                  'background.url',
                  'minHeight',
                  'minHeight.value',
                  'padding',
                  'rounded',
                  'subtext',
                  'subtext.color',
                  'subtext.size',
                  'title',
                  'title.color',
                  'title.size',
                  'background',
                  'textOffet',
                  'textPosition',
                  'sectionPadding',
                ],
              },
              'landing.flat-reviews': {
                populate: [
                  'subtext',
                  'subtext.color',
                  'subtext.size',
                  'data',
                  'title',
                  'title.color',
                  'title.size',
                  'background',
                  'background.style',
                  'background.image',
                  'background.url',
                  'imageURL',
                ],
              },
              'component.hlvs-3d-component': {
                populate: '*',
              },
              'landing.hero-banner-w-image-themed': {
                populate: [
                  'background',
                  'background.style',
                  'background.image',
                  'background.url',
                  'background.imagePosition',
                  'padding',
                  'title',
                  'title.size',
                  'title.color',
                  'title2',
                  'title2.size',
                  'title2.color',
                  'subtext',
                  'subtext.size',
                  'subtext.color',
                  'padding',
                  'minHeight',
                  'minHeight.value',
                ],
              },
              'landing.side-by-side': {
                populate: [
                  'headingLeft',
                  'headingLeft.color',
                  'headingLeft.size',
                  'bodyLeft',
                  'bodyLeft.color',
                  'headingRight',
                  'headingRight.color',
                  'headingRight.size',
                  'bodyRight',
                  'bodyRight.color',
                  'padding',
                ],
              },
            },
          },
        },
      },
      { encodeValuesOnly: true /* prettify URL */ }
    );
    let parse = qs.parse(search);
    return parse;
  }, []);

  useEffect(() => {
    getProduct({
      id: resolvedSlug,
    });
  }, [resolvedSlug, getProduct]);

  useEffect(() => {
    if (!product) return;

    getRelatedProducts(product?.attributes?.modelSeries);
  }, [product, getRelatedProducts]);

  useEffect(() => {
    isResetLandingPage();
  }, [isResetLandingPage]);

  useEffect(() => {
    if (filterLandingPage) {
      getLandingPage({
        id: resolvedSlug,
        params: filterLandingPage,
      });
    }
  }, [resolvedSlug, filterLandingPage, getLandingPage, params]);

  useEffect(() => {
    setPageTheme(landingPage?.attributes?.pageTheme || 'default');

    document.title = isError
      ? MRCOOL
      : `${product?.attributes?.overview_meta_title ? `${product.attributes.overview_meta_title} | ${MRCOOL}` : MRCOOL}`;

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        'content',
        `${product?.attributes?.overview_meta_description ? `${product.attributes.overview_meta_description}` : 'Meta Description'}`
      );
    }
  }, [
    landingPage,
    isError,
    product?.attributes?.overview_meta_title,
    product?.attributes?.overview_meta_description,
    setPageTheme,
  ]);

  return (
    <>
      {isError ? (
        <NotFoundPage />
      ) : !landingPage ? (
        <ProductPageSkeleton />
      ) : (
        <>
          <MainSection data={landingPage?.attributes} />
          <ModalMap mapOpen={modalMapOpen} setMapOpen={setModalMapOpen} />
        </>
      )}
    </>
  );
};

export default ProductLandingGeneralPage;
